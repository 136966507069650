.blog {
	article {
		.entry-meta { padding-bottom: 1.5rem; }
	}
	h2.entry-title a { color: $brand-primary;}
}

.single {
	article { padding-top: 3rem; }
	.entry-meta { padding-bottom: 1.5rem; }
	.blog-sidebar {  padding-top: 3.5rem; }
}

h1.category { font-size: 1.5rem !important;  }	

section.widget {
	padding: 1rem 0;
}

.blog-sidebar {
    padding-top: 2rem;
	div.label { font-weight:bold; color:$brand-primary; font-size:1.25rem; }	
	div.widget { margin-bottom: 1.5rem; }	
	ul, li { 
		list-style-type:none; 
		padding: 0; 
		margin: 7.5px 0 7.5px 0; 
	}	
	
	.gform_widget div.label { margin-bottom: .5rem  }	
	.gform_wrapper .gfield_label { margin-bottom: 0 !important;  }	

}
	
#related-posts h3 { border-top: 1px solid #e3e3e3; padding-top:1rem; }
#related-posts li, #related-posts ul { list-style-type: none; padding: 0; margin: 7.5px 0; }
@media screen and (max-width:768px) { 
	section#related-posts { margin-top:0 }
	#related-posts h3 { border-top: 0; padding-top:0; font-size: 1.25rem !important; font-weight:700;  }
}
