/* COUPONS */
.rebate { padding: 10px; }		
.coupon { background: #003285; background:#ffc600; padding: 10px; text-align:center;  }	
.coupon .coupon-border { border: 2px dashed black;  padding: 15px 15px 0; position:relative  }	
.coupon img  { margin: 0 auto 1rem;   }			
.coupon .heading { margin-bottom:15px; font-size:1.85rem; font-weight:bold; line-height:1.1;  }
.coupon .subheading {margin-bottom:15px; font-size: 1.5rem; font-weight:bold; line-height:1.1; }
.coupon .subheading2 {margin-bottom:15px; font-weight:bold; }	
.coupon .expiration { font-size: .8rem   }
.coupon .fineprint {margin-bottom:15px; font-size: .8rem  }	
.coupon div.share  { padding: 0 5px 5px; }		
.coupon div.share a { color:#222; font-size:30px; float:left;  } /* print icon */
.coupon div.share img { margin: 0 0 0 auto; }	
	
body.page-template-template-coupons-rebates .coupon .coupon-border { height:100%;  }	/* COUPONS PAGE ONLY */	
body.page-template-template-coupons-rebates div.coupon { height:100%; margin-bottom:70px;  }	
body.page-template-template-coupons-rebates div.share { position: absolute; bottom: 15px;width: calc(100% - 30px); }	


/* CTA in sidebar */
div.cta { 
	padding:30px; text-align:center; color:#fff; 
	background:$brand-primary; background: linear-gradient(lighten($brand-primary, 3%), $brand-primary, darken($brand-primary, 3%)); 
	/*border: 2px solid $gold;*/ border: 2px solid $dark-green; 
}	
div.cta ul.subnav { text-align:left; padding-left:0; list-style-type:none;  margin-bottom:0 }
div.cta ul.subnav li { padding-bottom:.35rem;  }
div.cta ul.subnav a { color:#fff; }


/* TABS */
.tab-set {
	margin: 0 0 -1px 0;
	padding:0;
	list-style:none;
	> li { 
		display:inline-block;
		padding: 1rem; 
		background:$light-gray; 
		border-top: 1px solid $almost-gray;
		border-left: 1px solid $almost-gray;
		cursor: pointer;
		font-size: 1.125rem;
		text-transform: uppercase;  
		&:last-child { 
			border-right: 1px solid $almost-gray;
		}
		&.active {
			background: #fff; 
			border-top: 3px solid $brand-secondary; 
			border-bottom: 1px solid #fff; 
		} 
	}
}
@media screen and (max-width:767px) {
	.tab-set { display:block;  }
	.tab-set>li { float: none; margin-bottom: -1px; width:100%; border-right: 1px solid $almost-gray;  }
}

/* shop for tires page */
.shop-tires .tab-set>li { text-align:center; width:50%; font-weight:bold; padding: 1rem .75rem 1.15rem; }  
.shop-tires .tab-set>li:last-child { border-left:0 }  
.shop-tires .tab-set>li.active {  background: $brand-primary; color:#fff; border-top: 9px solid $brand-primary; border-bottom: 0; }
@media screen and (max-width:480px) { .shop-tires .tab-set>li { font-size: 1rem; } }
@media screen and (max-width:360px) { .shop-tires .tab-set>li { width:100%; } }

.tab-content { 
	padding: 3rem 1.2rem 1.2rem 1.2rem; 
	border: 1px solid $almost-gray; 
	> div { display: none; } 
	> div.active { display:table; width:100%; } 
	h3.gsection_title {font-size: 1.25rem !important; margin-top: 3rem; }
}           


/* ACCORDION */
section#accordion { margin-top:0 }
.accordion {
	margin-bottom: 1rem;
	.accordion-label {  /* button */
		width: 100%;
		background: $white;
		color: $body;
		text-align: left;
		font-weight:bold; 
		padding: 1rem;
		border: 0; 
		cursor: pointer;

		&:before {
			content: "+";
			/*padding-right: 10px;  */
			float: left; margin-right:15px;
			font-size: 1em;
			font-weight: bold;
			color: $brand-secondary;
			transition: transform 0.3s ease-in-out;
		}
		&.active {
			&:before { transform:rotate(-135deg); }
		}
	}
	ul { margin: 0; padding: 0;	}
	li { list-style-type: none; }
	> ul > li { border-bottom: 1px solid $light-gray; }

	.accordion-content {
		max-height: 0; overflow: hidden; transition: all 0.35s ease-in-out;
		ul {
			padding-left: 2.5rem; padding-bottom: 1rem; 
		}	
		li {
			padding: .25rem 1rem;
			background: $white;
			list-style-type:disc;
		}
		div { padding: 0 1rem; }
		img { margin-bottom: 1rem;  }
	}
}


/* CARDS */
.card {
	border: none;
	box-shadow: $dp-2;
	border-radius: 0;
	.card-img-top { }
}
.card-bg-primary {
	background: $brand-primary;	
}
.card-bg-secondary {
	background: $brand-secondary;
}
.card-block {
	text-align:center;
	padding: 1rem .5rem;
}


/* EKKO-LIGHTBOX MODALS */
.modal-content {
	background: transparent;
	border: none;
	border-radius: 0;
	& > .modal-header {
		border-bottom: 0;
		padding: 15px 0 0 0;
		& > .close {
			color: $white;
			opacity: 1;
			&:focus { opacity: 1;       }
			&:hover { opacity: 1;       }
		}
	}
	.ekko-lightbox-nav-overlay {
		top:43%; position:absolute; width:100%;
		& > a {
			color: $white;
			padding: 0.5em;
			opacity: 1;
			font-size:46px;
			text-decoration:none;
			&:first-child { float:left; } 		
			&:last-child { float:right; margin-right:5%; } 
		}
	}
}


/* VIDEOS */
.video-container {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
	iframe {
		position: absolute;
		top:0;
		left: 0;
		width: 100%;
		height: 100%;
	}
}


/* OFFCANVAS NAV */
.offcanvas-toggle.is-open .icon-bar:nth-child(3) {  
    -webkit-transform: rotate(-45deg) translate(5px,-5px);
    transform: rotate(-45deg) translate(5px,-5px);
}

#js-bootstrap-offcanvas.in { background: $black; padding-bottom:3rem;  }
.navbar-toggle {
	.icon-bar {
		display: block;
		width: 25px;
		height: 3px;
		border-radius: 1px;
	}
	.icon-bar+.icon-bar {
		margin-top: 4px;
	}
}
header .nav-primary {
	.in .nav {
		> li { padding-top:12px !important; }  /* top level only */ 
		> li > a { color: $gold !important; font-weight: 600 !important;}  /* top level only */ 
		display: table;
		li {
			padding: 0;
			a {
				color:#fff; font-weight: 400;
				padding: 0.6em 0 0.5em;
				border-bottom: 1px solid #444;
				display:block;
				width: 100%;	
			}
        	&.mobile { display:block; }			
		}
		li .sub-menu {
			display: block;
			padding-top:0;
			padding-bottom: 0;
			padding: 0; 
			background: #000;
			li { padding: .25rem 0; }
			li .sub-menu {
				display: block;
				position: relative;
				left: auto;
				top: auto;
				
			}
			a { color:#fff; text-transform: capitalize !important; padding: .45rem 0;  }
		}
	}
}




/* media queries */
@media (max-width: 992px) {}
@media (max-width: 768px) {}

.row.no-gutters {
	margin-right: 0 !important;
	margin-left: 0 !important;
	& > [class^="col-"],
	& > [class*=" col-"] {
		padding-right: 0 !important;
		padding-left: 0 !important;
	}
}


/* from /wp-content/themes/tamarin/bower_components/slick-carousel/slick/slick-theme.css (not loading this url) */
@charset 'UTF-8';
/* Slider */
.slick-loading .slick-list {
    background: #fff url('./ajax-loader.gif') center center no-repeat;
}
/* Icons */
@font-face {
    font-family: 'slick';
    font-weight: normal;
    font-style: normal;
    src: url('/wp-content/themes/tamarin/bower_components/slick-carousel/slick/fonts/slick.eot');
    src: url('/wp-content/themes/tamarin/bower_components/slick-carousel/slick/fonts/slick.eot?#iefix') format('embedded-opentype'), url('/wp-content/themes/tamarin/bower_components/slick-carousel/slick/fonts/slick.woff') format('woff'), url('/wp-content/themes/tamarin/bower_components/slick-carousel/slick/fonts/slick.ttf') format('truetype'), url('/wp-content/themes/tamarin/bower_components/slick-carousel/slick/fonts/slick.svg#slick') format('svg');
}
/* Arrows */
.slick-prev,
.slick-next {
    font-size: 0;
    line-height: 0;
    position: absolute;
    top: 38%;
    display: block;
    width: 20px;
    height: 20px;
    padding: 0;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);
    cursor: pointer;
    color: transparent;
    border: none;
    outline: none;
    background: transparent;
}
.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
    color: transparent;
    outline: none;
    background: transparent;
}
.slick-prev:hover:before,
.slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before {
    opacity: 1;
}
.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
    opacity: .25;
}
.slick-prev:before,
.slick-next:before {
    font-family: 'slick';
    font-size: 30px;
    line-height: 1;
    opacity: .75;
    color: white;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.slick-prev {
    left: -25px;
}
[dir='rtl'] .slick-prev {
    right: -25px;
    left: auto;
}
.slick-prev:before {
    /*content: 'â†';*/
	content: "←";
}
[dir='rtl'] .slick-prev:before {
    /*content: 'â†’';*/
	content: "←";
}
.slick-next {
    right: -25px;
}
[dir='rtl'] .slick-next {
    right: auto;
    left: -25px;
}
.slick-next:before {
    /*content: 'â†’';*/
	content: "→";
}
[dir='rtl'] .slick-next:before {
    /*content: 'â†';*/
	content: "→";
}

/* Dots */
.slick-dotted.slick-slider {
    margin-bottom: 30px;
}
.slick-dots {
    position: absolute;
    bottom: -50px;
    display: block;
    width: 100%;
    padding: 0;
    margin: 0;
    list-style: none;
    text-align: center;
}
.slick-dots li {
    position: relative;
    display: inline-block;
    width: 35px;
    height: 35px;
    margin: 0 5px;
    padding: 0;
    cursor: pointer;
}
.slick-dots li button {
    font-size: 0;
    line-height: 0;
    display: block;
    width: 35px;
    height: 35px;
    padding: 5px;
    cursor: pointer;
    color: transparent;
    border: 0;
    outline: none;
    background: transparent;
}
.slick-dots li button:hover,
.slick-dots li button:focus {
    outline: none;
}
.slick-dots li button:hover:before,
.slick-dots li button:focus:before {
    opacity: 1;
}
.slick-dots li button:before {
    font-family: 'slick';
    font-size: 6px;
    line-height: 20px;
    position: absolute;
    top: 0;
    left: 0;
    width: 20px;
    height: 20px;
	content: "";
    text-align: center;
    opacity: .6;
    color: #000;
	border: 2px solid #fff;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.slick-dots li.slick-active button:before {
    opacity: 1;
    color: black;
}
@media screen and (max-width:767px) { button.slick-arrow { display:none !important; } }