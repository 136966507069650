/* @include media-breakpoint-down(sm) { padding-top: 0; }  */

#resources-panel {
	background: url('/wp-content/uploads/2022/02/bg-grid.png');
	background-size: cover;
	color: $white;
	text-align: center;
	padding: 3rem 0;
	margin: 0;
	span { font-size:1.5rem; }
	img {
		display: inline-block;
		border: 2px solid #fff;
		max-width: 170px; height: auto;
		margin: 8px; 
	}
}

#location-services {
	background: $light-gray;
	text-align:center;
	img { margin: 0 auto; }
}
	
#featured-testimonial { 
	background: $brand-primary;
	color: #fff; 
	margin:0; 
	padding: 3rem 0; 
	.testimonial { 
		background: darken($brand-primary, 50%); 
		padding: 2rem; 
		font-style: italic;
	}
}

#write-review {
	background: $brand-primary;
	color:#fff;
	span {
		font-size: 1.5rem;
		font-weight: bold;
	}
	a {
		color:#fff;
		text-decoration:underline;
	}
	@include media-breakpoint-down(sm) {
		.col-sm-3 { float:left; width:20%; }
		.col-sm-9 { float:left; width: 79%; }
	}
	.col-md-4 { padding-bottom: .75rem; }
	.col-md-4 > div { display:table; }	
	.col-md-4 img { float:left; }
}


#subscribe-panel {}

#hiring { 
	margin: 0; 
	padding: 1rem; 
	background: $gold; 
	h2 { color:#000; margin:0; padding:0; font-size:1.5rem!important; }
	a { color:#000; }	
}
	
#footer > .container > .row > div { padding-bottom: 1rem; }

footer.site-footer {
	padding: 3.15em 0 2rem;
	background-color: $black;
	color: $white;
	font-size: 14px;
	img {  display:inline-block; }
	div.label { font-weight:bold; font-size: 1.1rem; } 
	strong { font-size: 1.1rem; }
	ul.menu {
		@include clearfix(); 
		margin: 0;
		padding: 0; 
		list-style-type: none;
		li {
			display:inline; 
			border-right: 1px solid #666; 
			a {
				color: #fff; 
				padding: 0 5px 0 3px
			}
		}
		li:last-child { border-right: 0; }
		
	}
	
	#menu-areas-served li { padding-right: 6px; margin-right: 6px;}	
	#menu-areas-served li a { padding-left: 0;}	
	
	.social { 
		margin-top: 1rem; 
		padding-bottom: .5rem;		
	}
	
	.copyright {
		text-align: center;
		margin-bottom: 0;
		a { color:#fff; text-decoration:underline; }
	}
	 
}