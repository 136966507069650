.home {
	#hero { 
		background: #000 url('/wp-content/uploads/2021/11/bg-tires.jpg') no-repeat; 
		background-size: cover; 
		background-position: top center; 
		padding-bottom: 1em; 
		padding-top: 80px; 
		padding-bottom: 50px; 
		margin-top: 0; 
		@include media-breakpoint-down(sm) { padding-top: 30px; padding-bottom: 10px; }
		img { 
			margin:0 auto;
			@include media-breakpoint-down(sm) { margin:0 auto 30px; }
		}
		img.slogan { 
			width:200px; height:200px; position: absolute; right: 0; top: 210px;
			@include media-breakpoint-down(md) { width:120px; height:120px; position: absolute; right: 0; top:180px; }
			@include media-breakpoint-down(sm) { display: none;  }
		}
	}
	.slogan-mobile { 
		display: none; 
		@include media-breakpoint-down(sm) { display: block; text-align: center; margin-bottom: 30px; }  		
	} 

	#content { margin-top:0; }
	
	#coupons { 
		margin-top: 0;
		padding: 3.5rem 0;
		text-align:center;
		background: #000 url('/wp-content/uploads/2022/02/center-line-road.jpg') no-repeat;
		background-size: cover;
		background-position: center;  
		h2 { 
			color: $gold;
			font-weight: bold;
			margin-bottom: 2rem;
		}
	}
	
	#video-panel { 
		margin-bottom: 0;
		padding: 2.5rem 0 3.5rem; 
		background:#000 url('/wp-content/uploads/2022/02/bg-tread.jpg') no-repeat;
		background-size: cover; 
		h2 {	
			color:#fff;
			margin-bottom:1.5rem; }	
	}	
	
	#featured-brands {  
		img { display:inline-block; margin:5px 10px; }
		h2 { margin-bottom: 1rem; }
	}
} 	

/* CONTACT and LOCATION PAGES */
div.contact-block-wrapper { 
	margin-bottom: 3rem; 
	h2 { margin-bottom:1rem; }	
} 
div.contact-block { 
	padding: 10px 0 0 16px; 
	margin: 0 0 0 5px; 
	border-left: 3px solid $brand-primary; 
	.bold { font-weight: 600; }	
	.mdi.icon { color:$brand-primary; font-size:20px; }
	span.label { font-weight:400; display:inline-block; min-width:48px; }	
	img.storephoto { width:350px; margin-bottom:25px; }
	.storehours { font-size:.91rem; }
	.storehours td { padding-right: 6px; }	
}
div.googlemap iframe { width:100%; height:380px; border:0}  


/* TIRE BRANDS PAGE */
.all-brands img { display:inline-block; margin:5px 10px; }


/* CAREERS PAGE */
.career-post-sidebar { 
	.btn { width:100%; }
}
.career-post-footer { 
	@include media-breakpoint-down(md) {
		display:none;
	}
}


@media (max-width: 768px) {}

@media (max-width: 544px) {}

// Webkit
@-webkit-keyframes wobble  {
  0%  { -webkit-transform:  rotate(0deg); }
  20%  { -webkit-transform:  rotate(2deg); }
  50%  { -webkit-transform:  rotate(-2deg); }
  100%  { -webkit-transform:  rotate(0deg); }
}