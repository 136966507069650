#topbar { 
	background: $brand-primary;
	color: #fff; 
	text-align: center;
	padding: 3px; 
	a { text-decoration:underline;color:#fff; }
}

header.banner {
	padding-top: 0.5rem;
	padding-bottom: 0;
	top: 0;
	width: 100%;
	z-index: 9999;
	background-color: $black;
	transition: all 0.5s;

	@include media-breakpoint-down(md) {
		img.custom-logo { max-width:40%; height:auto; float:left; } 
		header div.location-button { display: table; margin-top: 9px; text-align: center; }
		header a.btn.location { padding: .1rem .75rem; }
	}
	
	#menu-header-menu {
		@include media-breakpoint-down(md) { display: none;   }
		li {
			padding: 0 5px; 
			color: $gray;
			&:last-child {
				a { margin-right: 0; }
			}
			a {
				color: #fff;
				font-size: 0.85rem;
				font-weight: 600;
				text-transform: uppercase;
				padding: 0 0.33rem; 
			}
		}
		li.schedule:before, li.contact:before {
    		display: inline-block; font-family: "Material Design Icons";
			line-height: 1; font-size: inherit; text-rendering: auto; -webkit-font-smoothing: antialiased; 
		}	
		li.schedule:before { content: "\F0F0"; }
		li.contact:before { content: "\F1F0"; }
		li.extra-pad { padding-left: 8px; }
	}
	
	#menu-mobile-header-menu { 
		display: none;  
		@include media-breakpoint-down(md) { display: flex; }
		float: right; 
		margin-right: 50px; 
		li { text-align:center; }
		li a { font-size: .8em; color:#fff; padding: 0 5px 0; display:block;  }		
		li.schedule:before, li.contact:before {
    		display: inline-block; font-family: "Material Design Icons"; color:#fff; padding-top: .75rem;
			line-height: 1; font-size: 22px; text-rendering: auto; -webkit-font-smoothing: antialiased; 
		}	
		li.schedule:before { content: "\F0F0"; }
		li.contact:before { content: "\F1F0"; }
	}
	
	div.locations { color:#fff; margin-top: 1rem; }
	div.locations > div.col { white-space:nowrap; margin-top:12px }	
	div.locations a { color:#fff; }
	@include media-breakpoint-down(sm) {
		div.locations > div.col { width:100%;text-align:center;  }
	}
	
	.menu-main-container { position: relative; } 

	.nav-primary {
		margin-top: 1rem;
		transition: margin-top 0.1s;
		display: table; width: 100%;
		background: $brand-primary; 
		color: #fff; 
		padding: 3px 15px;
		@include media-breakpoint-down(md) { padding: 10px 15px;   } 
		
		li {
			transition: border-color 0.2s ease;
			position: relative;
			padding: .5rem .25rem; 

			& > ul {
				display: none;
				padding: 1rem;
			}
			a {
				font-size: 0.9rem;
				text-transform: uppercase;
				padding: .5rem .4rem .5rem .4rem;
				color: #fff;
				text-decoration: none;
				font-weight: 600;
				&:hover {
					color: $gold;
				}
			}
			@include media-breakpoint-up(lg) {  /* desktop only */
				&:hover {
					& > ul {
						display: block;
						position: absolute;
						box-shadow: 0 1px 5px 0 rgba(0,0,0,.2),0 2px 2px 0 rgba(0,0,0,.14),0 3px 1px -2px rgba(0,0,0,.12); /* dp-2 without top shadow */
						transition: display 0.5s ease;
						width: 20rem;
					}
				}
			}
			
			&.mobile {
				display:none;
			}			
			.sub-menu {
				z-index: 9999;
				background: #fff;
				line-height: 1; 
				li { list-style-type: none; }
				a {
					color:#000;
					padding: 0.5rem;
				}
			}
			
			&.location a {  /* optional gold Location menu item in primary nav */
				background: #ffc600 url('/wp-content/uploads/2022/01/icon-marker.png') no-repeat 5px 10px; 
				padding-left: 24px;
			}
			&.location a:hover { color: #000; }
		}
		@include media-breakpoint-up(lg) {  /* desktop only */
			li:after { 	
				content: "|"; margin-left: 10px;  
				color:#fff;  
			}
			li.noborder:after { 
				content: ""; 
			}
		}
		.current-menu-item {
			a { color: $brand-secondary; }
		}
	}
}

@media screen and (min-width:992.1px) {  /* Mega menus on desktop */
	.nav-primary li.mega { position: static !important; }
	.nav-primary li.mega > .sub-menu { position:absolute; width:920px !important; left:0; padding-bottom:25px; padding-top:15px; padding-left:30px;  }
	.nav-primary li.mega .sub-menu li, header.banner .nav-primary li.mega .sub-menu li a  { padding-left: 0; }	
	.nav-primary li.mega div.mega-col-left { float:left; width:31%; padding: 0 3.5% 0 0; }	
	.nav-primary li.mega div.mega-col-center { float:left; width:31%; padding: 0 3.5% 0 0; }	
	.nav-primary li.mega div.mega-col-right { float:left; width:31%; padding: 0 0 0 0; }	
}
@media screen and (min-width:1200px) {  /* Mega menus on desktop */
	.nav-primary li.mega > .sub-menu { width:1110px !important;  }
}


/*  media queries and responsive  */
.navbar-toggle { display: none; }

@media (min-width: 993px) and (max-width:1199px) {  /* prevent wrapping at 1024 */
	header.banner .nav-primary li.mega >.sub-menu { width: 1000px; }
	header.banner #menu-primary-navigation > li > a { font-size: .9rem; padding: .5rem .5rem; } /* first level only */
}

@media (max-width: 992px) {
	header.banner {
		.navbar-toggle {
			display: block;
			padding: 0.25rem 0.75rem;
			float: right;
			background: transparent;
			border: 0;
			.icon-bar {
				background: #fff;
			}
		}
		.navbar-toggle.offcanvas-toggle.js-offcanvas-has-events {
			position: absolute;
			right: 5px;
			top: 10px;
			cursor: pointer; 
		}
		.navbar-offcanvas.in {
			left: -250px !important;
		}
	}
}

@media (max-width: 768px) {
	.nav-primary .navbar-offcanvas.in {
		left: 300px !important;
	}
	.main-nav .navbar-offcanvas.offcanvas-transform.in {
		transform: translateX(250px) !important;
	}
}

@media (max-width: 544px) {}



/* fix for fixed header not lining up with admin bar */
/*.admin-bar header {
	top: 32px;
}*/